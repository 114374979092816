import { useSelector } from 'react-redux'
import { messageActions, snackbarMsgList } from 'src/store/message.slice'
import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { store } from '../store'

export default function Message() {
  const { enqueueSnackbar } = useSnackbar()
  const msgList = useSelector(snackbarMsgList)

  useEffect(() => {
    if (msgList?.length) {
      msgList.forEach((item) => {
        enqueueSnackbar(item.message, item.options)
      })
      store.dispatch(messageActions.clearSnackbarList())
    }
  }, [msgList, enqueueSnackbar])

  return <></>
}
